<template>
  <q-layout view="lHh lpR lff">
    <dbr-header :on-menu-click="toggleLeftDrawer"/>

    <q-drawer
      v-model="leftDrawerOpen"
      bordered
      class="bg-primary text-white"
      show-if-above
    >
      <dbr-menu/>
    </q-drawer>

    <q-page-container>
      <router-view/>
      <dbr-footer/>
    </q-page-container>

  </q-layout>
</template>

<script>

import { defineComponent, ref, onMounted } from 'vue';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Menu from '@/components/Menu';
import { userStorage } from '@/lib/userStorage';

export default defineComponent({
  name: 'MainLayout',

  components: {
    dbrMenu: Menu,
    dbrHeader: Header,
    dbrFooter: Footer,
  },

  setup() {
    const leftDrawerOpen = ref(false);

    onMounted(() => {
      leftDrawerOpen.value = !!userStorage.leftDrawerOpen;
    });

    return {
      leftDrawerOpen,
      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
        userStorage.leftDrawerOpen = leftDrawerOpen.value;
      },
    };
  },
});
</script>
